<template>
  <div>
    <v-app-bar location="top" density="comfortable" :color="`white`" prominent>
      <template #prepend>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer" />
        <v-app-bar-title>
          <router-link to="/" class="text-decoration-none">
            <v-img
              :width="200"
              src="/logo.png?url"
            />
          </router-link>
        </v-app-bar-title>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      :location="$vuetify.display.mobile ? 'bottom' : 'start'"
      temporary
      width="280"
    >
      <v-list density="compact" nav>
        <v-list-group value="Movimenti">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-truck"
              title="Movimenti Interni"
            />
          </template>

          <v-list-item
            title="Tutti Movimenti"
            value="movimenti"
            :to="`/movimenti`"
          />
          <v-list-item
            title="Movimenti Ingressi"
            value="ingressi"
            :to="`/movimenti/ingressi`"
          />
          <v-list-item
            title="Movimenti Uscite"
            value="uscite"
            :to="`/movimenti/uscite`"
          />
        </v-list-group>

        <v-list-group v-if="ruoli.includes('logistica')" value="UscitaConsegna">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-truck-delivery"
              title="Uscite Consegna"
            />
          </template>
          <v-list-item
            title="Tutte Le Uscite"
            value="allUscite"
            :to="`/uscite_consegna/`"
          />
          <v-list-item
            title="Consegne Programmata"
            value="consegneProgrammata"
            :to="`/uscite_consegna/consegne_programmata`"
          />
          <v-list-item
            title="Consegne In Corso"
            value="consegneInCorso"
            :to="`/uscite_consegna/consegne_in_corso`"
          />
        </v-list-group>

        <v-list-group v-if="ruoli.includes('logistica')" value="Merci">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-clipboard-flow"
              title="Merce"
            />
          </template>

          <v-list-item
            title="Merce in arrivo"
            value="merciInArrivo"
            :to="`/merci/arrivo`"
          />
          <v-list-item
            title="Ordini Arrivati"
            value="merciArrivati"
            :to="`/merci/arrivati`"
          />
          <v-list-item
            title="Ordini Incompleti"
            value="ordini_incompleti"
            :to="`/merci/incompleti`"
          />
          <v-list-item
            title="Ordini Saldati Consegnabili"
            value="merciPronteConsegna"
            :to="`/merci/pronte_consegna`"
          />
        </v-list-group>
        <v-list-group v-if="ruoli.includes('fornitore')" value="Ordini di acquisto">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              prepend-icon="mdi-store-check"
              title="Ordini Acquisto"
            />
          </template>
          <v-list-item
            title="Tutti gli Ordini"
            value="tuttiOrdiniAcquisto"
            to="/ordini_acquisto/"
          />
          <v-list-item
            title="Da prendere in carico"
            value="prendereCarico"
            to="/ordini_acquisto/da_prendere_in_carico"
          />
          <v-list-item
            title="Presi in carico"
            value="presiCarico"
            to="/ordini_acquisto/presi_in_carico"
          />
        </v-list-group>

        <v-list-group v-if="ruoli.includes('logistica')" value="ordini_vendita_group">
          <template #activator="{ props }">
            <v-list-item
              v-bind="props"
              title="Ordini Vendita"
              prepend-icon="mdi-invoice-text-check"
            />
          </template>

          <v-list-item
            title="Tutti Ordini Vendita"
            value="ordini_vendita"
            :to="`/ordini_vendita`"
          />
          <v-list-item
            title="Ordini Vendita Pronti Consegna"
            value="ordini_vendita_pronte_consegna"
            :to="`/ordini_vendita/pronte_consegna`"
          />
          <!-- <v-list-item
            title="Ordini Vendita Incompleti"
            value="ordini_vendita_incompleti"
            :to="`/ordini_vendita/incompleti`"
          /> -->
        </v-list-group>

        <v-list-item
          v-if="ruoli.includes('logistica')"
          title="Assistenza"
          value="assistenza"
          prepend-icon="mdi-face-agent"
          to="/ticket_assistenza/"
        />
      </v-list>

      <template #append>
        <div class="pa-2">
          <v-btn block :loading="authLoading" @click="logOut()">
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import { useAuthStored } from "~/store/authStored";

export default {
  data: () => ({
    drawer: false
  }),
  computed: {
    ...mapState(useAuthStored, ["ruoli", "authLoading"])
  },
  methods: {
    ...mapActions(useAuthStored, ["logUserOut"]),
    logOut () {
      this.logUserOut();
      this.$router.push("/login");
    }
  }
};
</script>
